/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */
import wretch from "wretch";
import { assert, object, number, string, array, is, Describe, min, StructError } from "superstruct";
import { blockScreenScroll, allowScreenScroll } from "../utils/screens";
import { updateCartStatistics, handleRemoveOfPromocode } from "../components/api";

const API_ADD_CART_ITEM_ENPOINT = "/cart/add/id/<prodID>/quantity/<quantity>/<upsell>";
const API_REMOVE_CART_ITEM_ENDPOINT = "/cart/delete/index/<prodID>";
const API_INCREASE_CART_ITEM_ENDPOINT = "/cart/plus/index/<prodID>";
const API_DECREASE_CART_ITEM_ENDPOINT = "/cart/minus/index/<prodID>";

const APT_CART_ESTIMATE = "/cart/cartestimate";

type TproductCartInput = {
  productID: number;
  quantity: number;
};

const openCartModal = (): void => {
  const $mobileMenu = document.querySelector<HTMLDialogElement>("[data-cart-dialog]");
  if ($mobileMenu) {
    // Show modal
    $mobileMenu.showModal();
    $mobileMenu.classList.add("-translate-x-full");
    //   Block screen
    blockScreenScroll();
    //   On close remoce class once
    $mobileMenu.addEventListener(
      "close",
      (): void => {
        $mobileMenu.classList.remove("-translate-x-full");
      },
      { once: true }
    );
  }
};

const fetchEstimate = async () => {
  const payload = grabAllIDsAndQuantities();
  if (payload.length) {
    var data = new FormData();
    data.append("virtualCart", JSON.stringify(payload));

    const rawResponse = await fetch(APT_CART_ESTIMATE, {
      method: "POST",
      body: data,
    });

    if (rawResponse.status === 200) {
      const raw = await rawResponse.text();
      // console.log(raw);
      const response = JSON.parse(raw);
      // console.log(response.closePromotion);
      // console.log(response.items);
      // console.log(response.matchedPromotions);
      // console.log(response.cart);

      const $el1 = document.querySelectorAll("[data-virtual-price]");
      $el1.forEach(($e) => {
        $e.innerHTML = parseFloat(response.cart.cartPrice).toLocaleString("pl-PL", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      });
      const $el2 = document.querySelectorAll("[data-virtual-oldprice]");
      $el2.forEach(($e) => {
        $e.innerHTML = parseFloat(response.cart.cartPriceBefore).toLocaleString("pl-PL", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      });
      const $el3 = document.querySelectorAll("[data-virtual-savings]");
      $el3.forEach(($e) => {
        $e.innerHTML = parseFloat(response.cart.cartDifference).toLocaleString("pl-PL", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      });
      const $el4 = document.querySelectorAll("[data-savings]");
      $el4.forEach(($e) => {
        $e.setAttribute("data-savings", response.cart.cartDifference);
      });
      const $el5 = document.querySelectorAll("[data-virtual-servings]");
      $el5.forEach(($e) => {
        $e.innerHTML = response.cart.cartServings;
      });
      const $el6 = document.querySelectorAll("[data-virtual-perserving]");
      $el6.forEach(($e) => {
        $e.innerHTML = parseFloat(response.cart.cartPerServings).toLocaleString("pl-PL", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      });

      const $el7 = document.querySelectorAll("[data-show-promo]");
      $el7.forEach(($e) => {
        if (response.closePromotion.length === 0 && response.matchedPromotions.length) {
          $e.innerHTML = "Gratulacje! Twój rabat został odliczony.";
        }

        if (response.closePromotion.length) {
          const first = [...response.closePromotion].shift();
          $e.innerHTML = first.display;
        }
      });
    }
  }
};

const quantityInput = function (event: Event): void {
  if ("parent" in this) {
    const parent = this.parent as HTMLDivElement;
    const $input = parent.querySelector<HTMLInputElement>("[data-product-quantity-input]");
    if ($input) {
      const $button = event.currentTarget as HTMLButtonElement;
      const quantity = parseInt($button.dataset.update || "0");
      const inputCurrentValue = parseInt($input?.value || "0");
      const inputMin = parseInt($input?.min || "0");
      const inputMax = parseInt($input?.max || "1000");

      const newQuantitty = Math.min(Math.max(quantity + inputCurrentValue, inputMin), inputMax);
      $input.value = String(newQuantitty);
      fetchEstimate();
    }
  }
};

const handleQuantityAmountButtons = (): void => {
  const $quantityInputs = document.querySelectorAll<HTMLDivElement>("[data-product-quantity]");
  $quantityInputs.forEach(($singleInput) => {
    const $buttons = $singleInput.querySelectorAll<HTMLButtonElement>("[data-update]");
    $buttons.forEach(($inputElement) => {
      $inputElement.disabled = false;
      $inputElement.addEventListener("click", quantityInput.bind({ parent: $singleInput }));
    });
  });
};

const validateInput = function (): void {
  this.value = Number.isNaN(this.valueAsNumber) ? 0 : this.valueAsNumber;
};

const validateNumericalInputValues = (): void => {
  const $numericalInputs = document.querySelectorAll<HTMLInputElement>("[data-product-quantity-input]");
  $numericalInputs.forEach(($singleNumericalInput) => {
    $singleNumericalInput.addEventListener("input", validateInput);
  });
};

export const initCartStuff = (): void => {
  updateCartStatistics();
  handleQuantityAmountButtons();
  validateNumericalInputValues();
  initCartPromocodeSubmitButtons();
};

const initCartPromocodeSubmitButtons = (): void => {
  const $buttons = document.querySelectorAll<HTMLButtonElement>("[data-cart-promocode-submit]");
  $buttons.forEach(($singlebutton) => {
    $singlebutton.disabled = false;
  });
};

const grabAllIDsAndQuantities = (): TproductCartInput[] => {
  const $inputComponents = document.querySelectorAll<HTMLDivElement>("[data-product-quantity]");

  let products: TproductCartInput[] = [];
  $inputComponents.forEach(($inputComponent) => {
    const $inputField = $inputComponent.querySelector<HTMLInputElement>("[data-product-quantity-input]");
    if ($inputField) {
      const productID = parseInt($inputComponent.dataset.productQuantity || "0");
      const selectedQuantity = parseInt($inputField.value || "0");
      products.push({
        productID: productID,
        quantity: selectedQuantity,
      });
    }
  });

  return products.filter((singleElement) => checkPayloadValidity(singleElement));
};

const checkPayloadValidity = (singeItem: TproductCartInput): boolean => {
  const PayloadStructure: Describe<TproductCartInput> = object({
    productID: min(number(), 0, { exclusive: true }),
    quantity: min(number(), 0, { exclusive: true }),
  });

  try {
    assert(singeItem, PayloadStructure);
  } catch (e) {
    if (e instanceof StructError) {
      return false;
    }
  }

  return true;
};

const formatCartAPIURL = (payload: TproductCartInput): string => {
  return API_ADD_CART_ITEM_ENPOINT.replace("<prodID>", String(payload.productID))
    .replace("<quantity>", String(payload.quantity))
    .replace("<upsell>", "");
};

const sendItemsToCart = async (payload: TproductCartInput[], openModal = false) => {
  const requestsPackage = payload.map((single) => {
    return formatCartAPIURL(single);
  });

  // Requests must be sequential, because PHP is to fast to handle parallel
  for (const requestURL of requestsPackage) {
    await fetch(requestURL, {
      method: "GET",
    });
  }

  updateCartStatistics();
  if (openModal) {
    openCartModal();
  } else {
    window.location = "/koszyk";
  }
};

const showNoPayloadComs = () => {
  const $dialog = document.querySelector<HTMLDialogElement>("[data-coms]");
  if ($dialog) {
    // Show modal
    $dialog.showModal();
    //   Block screen
    blockScreenScroll();
    //   On close remoce class once
    $dialog.addEventListener("close", () => {}, { once: true });
  }
};

const handleAddingToCart = (): void => {
  const payload = grabAllIDsAndQuantities();
  if (payload.length) {
    sendItemsToCart(payload, true);
  } else {
    showNoPayloadComs();
  }
};
const handleAddingToCartAndCheckout = (): void => {
  const payload = grabAllIDsAndQuantities();
  if (payload.length) {
    sendItemsToCart(payload, false);
  } else {
    showNoPayloadComs();
  }
};

const $addToCartFromCardButtons = document.querySelectorAll<HTMLButtonElement>("[data-add-to-cart-from-card]");
$addToCartFromCardButtons.forEach(($singleButton) => {
  $singleButton.disabled = false;
  $singleButton.addEventListener("click", handleAddingToCart);
});

const $addToCartFromCardButtonsWithCheckout = document.querySelectorAll<HTMLButtonElement>("[data-add-to-cart-from-card-and-checkout]");
$addToCartFromCardButtonsWithCheckout.forEach(($singleButton) => {
  $singleButton.disabled = false;
  $singleButton.addEventListener("click", handleAddingToCartAndCheckout);
});

const handleCartItemDrop = function () {
  const prodID = parseInt(this.getAttribute("data-drop-cart-item"));
  fetch(API_REMOVE_CART_ITEM_ENDPOINT.replace("<prodID>", String(prodID)), {
    method: "GET",
  }).then((resp) => {
    window.location.reload();
  });
};

const $dropItemsFromCartButtons = document.querySelectorAll<HTMLButtonElement>("[data-drop-cart-item]");
$dropItemsFromCartButtons.forEach(($singleButton) => {
  $singleButton.disabled = false;
  $singleButton.addEventListener("click", handleCartItemDrop);
});

// TODO: fix typescript complains
const handleChangeQuantity = async function (event: Event) {
  if ("parent" in this) {
    const parent = this.parent as HTMLDivElement;
    const prodID = parseInt(parent.getAttribute("data-cart-update") || "0");
    const change = parseInt(event.currentTarget.getAttribute("data-update-in-cart") || "0");

    if (change > 0) {
      await fetch(API_INCREASE_CART_ITEM_ENDPOINT.replace("<prodID>", String(prodID)), {
        method: "GET",
      }).then((resp) => {
        window.location.reload();
      });
    }

    if (change < 0) {
      await fetch(API_DECREASE_CART_ITEM_ENDPOINT.replace("<prodID>", String(prodID)), {
        method: "GET",
      }).then((resp) => {
        window.location.reload();
      });
    }
  }
};

const $changeCartQuantityButtons = document.querySelectorAll<HTMLButtonElement>("[data-update-in-cart]");
$changeCartQuantityButtons.forEach(($singleButton) => {
  $singleButton.disabled = false;
  $singleButton.addEventListener("click", handleChangeQuantity.bind({ parent: $singleButton.parentNode }));
});

const $removePromocode = document.querySelector<HTMLButtonElement>("[data-remove-promocode]");
if ($removePromocode) {
  $removePromocode.disabled = false;
  $removePromocode.addEventListener("click", handleRemoveOfPromocode);
}
