/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */
import { blockScreenScroll } from "../utils/screens";

const NEWSLETTER_DIALOG_CONFIRMATION = "[data-dialog-newsletter]";
const NEWSLETTER_DIALOG_ERROR = "[data-dialog-newsletter-error]";

const prepareDialog = ($dialog: HTMLDialogElement): void => {
  $dialog.showModal();
  blockScreenScroll();
  $dialog.addEventListener("close", () => {}, { once: true });
};

const showNewsletterConfirm = (): void => {
  const $dialog = document.querySelector<HTMLDialogElement>(NEWSLETTER_DIALOG_CONFIRMATION);
  $dialog && prepareDialog($dialog);
};

const showNewsletterError = (): void => {
  const $dialog = document.querySelector<HTMLDialogElement>(NEWSLETTER_DIALOG_ERROR);
  $dialog && prepareDialog($dialog);
};

const handleNewsletterSub = (): void => {
  const $emailInput = document.querySelector<HTMLInputElement>("[data-newsletter-input-email]");
  const $nameInput = document.querySelector<HTMLInputElement>("[data-newsletter-input-name]");
  const $newsletterCheck = document.querySelector<HTMLInputElement>("[data-newsletter-input-check]");
  if ($emailInput && $newsletterCheck && $nameInput) {
    const email = String($emailInput.value).trim();
    const name = String($nameInput.value).trim();

    const consent = $newsletterCheck.checked;

    if (name.length && email.length && consent === true) {
      window._edrone.customer_tags = "Footer Form";
      window._edrone.action_type = "subscribe";

      window._edrone.email = email;
      window._edrone.first_name = name;

      window._edrone.init();
      showNewsletterConfirm();
    } else {
      showNewsletterError();
    }
  }
};

/**
 * Exports
 *********************************************************************************************************************/

export const initNewsletterForm = (): void => {
  const $newsletterButton = document.querySelector<HTMLButtonElement>("[data-newsletter]");
  if ($newsletterButton) {
    $newsletterButton.disabled = false;
    $newsletterButton.addEventListener("click", handleNewsletterSub);
  }
};
